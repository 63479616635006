import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, TableCell } from '@mui/material';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';

interface TableHeadCellProps {
  readonly tableState: PaginatedListState;
  readonly column: string;
  readonly label: string;
}

export const TableHeadCell = ({ tableState, column, label }: TableHeadCellProps) => {
  const handleSort = () => {
    const newDirection =
      column === tableState.params.sortBy && tableState.params.sortDirection === 'asc'
        ? 'desc'
        : 'asc';
    tableState.setSort(column, newDirection);
  };

  return (
    <TableCell>
      <Box component="span">
        <Link onClick={handleSort}>
          {label}{' '}
          {tableState.params.sortBy === column ? (
            <Box display="inline-block" marginLeft={1}>
              <FontAwesomeIcon
                icon={tableState.params.sortDirection === 'asc' ? faAngleUp : faAngleDown}
              />
            </Box>
          ) : null}
        </Link>
      </Box>
    </TableCell>
  );
};
